import jwtDecode from "jwt-decode";

/**
 * Represents a basic JWT from the Gateway.
 */
export type JwtToken = {
  /**
   * Subject of the token.
   */
  sub: number;

  /**
   * When the token expires (seconds).
   */
  exp: number;

  /**
   * When the token was issued.
   */
  iat: number;

  /**
   * The supplier associated with this token.
   */
  supplierId: string | undefined | null;

  /**
   * The contact associated with this token.
   */
  contactId: string | undefined | null;
};

/**
 * Attempts to parse a given token. Returns null if unable to do so.
 * @param token token to decode
 */
export const decodeToken = (token: string | null): JwtToken | null => {
  if (!token) {
    return null;
  }
  try {
    return jwtDecode<JwtToken>(token);
  } catch (e) {
    // do nothing if we can't decode the token
    return null;
  }
};

/**
 * Parses the given token (as a string) and attempts to determine if the token expires within the given TTL.
 * Also returns true if the token is already expired.
 *
 * @param token token to parse
 * @param ttl time to live
 */
export const isExpiresSoon = (token: string | null, ttl: number): boolean => {
  const decoded = decodeToken(token);
  // for whatever reason, the tokens are set to exp at a second, not ms
  const currentTime = Math.floor(Date.now() / 1000);
  const tokenExpire = decoded ? decoded.exp : currentTime;
  const diff = tokenExpire - currentTime;
  return diff < ttl;
};

/**
 * Parses the given token (as a string) and attempts to fetch the contact ID from it.
 * @param token token to parse
 */
export const getContactId = (token: string | null): string | null => {
  const decoded = decodeToken(token);
  if (decoded == null) {
    return null;
  }
  return decoded.contactId || null;
};

/**
 * Parses the given token (as a string) and attempts to fetch the supplier ID from it.
 * @param token token to parse
 */
export const getSupplierId = (token: string | null): string | null => {
  const decoded = decodeToken(token);
  if (decoded == null) {
    return null;
  }
  return decoded.supplierId || null;
};
